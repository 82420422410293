import { DocstoreDirectUpload } from "./doc_store_uploader";
const fields = document.querySelectorAll('.docstore-direct-upload');

fields.forEach(field => {
  const uploadInfo = Object.assign(document.createElement('div'), { className: 'docstore-upload-info form-control', style: 'display: none' });
  const selectedFileInfo = Object.assign(document.createElement('div'), { className: 'selected-file-info' });
  const form = field.closest('form');
  const uploadUrlField = form.querySelector(`#${field.id}_upload_url`);

  field.removeAttribute('name'); // Remove the name attribute so the field is no longer submitted
  field.addEventListener('change', handleDirectUploadChange);
  uploadInfo.style.display = 'none';
  uploadInfo.appendChild(selectedFileInfo);
  field.parentNode.appendChild(uploadInfo);

  if (uploadUrlField && uploadUrlField.value?.length) {
    fileUploaded(field, 'Uploaded');
  }
});

function uploadInProgress(field) {
  // Disabled submit button
  const form = field.closest('form');
  const formGroup = field.closest('.form-group') || field.parentNode;
  const submitButton = form.querySelector('input[type=submit]') || form.querySelector('button[type=submit]');

  submitButton.disabled = true;
  field.disabled = true;
  submitButton.value = 'Uploading';

  formGroup.classList.remove('has-error');
  field.parentNode.querySelectorAll('.help-block').forEach((target) => target.remove());
}

function uploadCompleted(response, field) {
  const form = field.closest('form');
  const submitButton = form.querySelector('input[type=submit]') || form.querySelector('button[type=submit]');
  submitButton.disabled = false;
  submitButton.value = submitButton.dataset.disableWith || 'Submit';

  fileUploaded(field, field.files[0].name);
  form.querySelector(`#${field.id}_upload_url`).value = `${response.urlPrefix}${response.binaries[0].id}`;
}

function removeFile(field) {
  const uploadInfo = field.parentNode.querySelector('.docstore-upload-info');
  const form = field.closest('form');

  field.style.display = 'block';
  uploadInfo.style.display = 'none';
  form.querySelector(`#${field.id}_upload_url`).value = null;
  field.value = '';
}

function fileUploaded(field, fileName) {
  const selectedFileInfo = field.parentNode.querySelector('.selected-file-info');
  const uploadInfo = field.parentNode.querySelector('.docstore-upload-info');
  const clickToRemoveTranslation = field.dataset.clickToRemove || 'Click to Remove';

  const fileInfoContent  = `<i class="fa fa-file-o"></i><span class="name"> ${fileName}</span>`;
  const fileInfoRemoveContent = `<i class="fa fa-file-o"></i><span class="name"> ${fileName}</span><span class="click-to-remove">${clickToRemoveTranslation}</span>`;
  // Display upload file name
  uploadInfo.style.display = '';
  field.style.display = 'none';

  selectedFileInfo.innerHTML = fileInfoContent;
  uploadInfo.addEventListener('click', (_e) => removeFile(field));
  selectedFileInfo.addEventListener('mouseenter', (_e) => {selectedFileInfo.innerHTML = fileInfoRemoveContent;});
  selectedFileInfo.addEventListener('mouseleave', (_e) => {selectedFileInfo.innerHTML = fileInfoContent;});
}

function uploadFiles(field) {
  uploadInProgress(field);

  const fetchUploadUrl = field.dataset.documentStoreFetchUploadUrl;
  const formGroup = field.closest('.form-group') || field.parentNode;
  const uploader = new DocstoreDirectUpload(field.files[0], fetchUploadUrl);

  uploader.create((error, response) => {
    if (error) {
      alert(error);
      formGroup.classList.add('has-error');
    } else {
      uploadCompleted(response, field);
    }

    field.disabled = false;
  });
}

function addFileError(field, errorMessage) {
  const formGroup = field.closest('.form-group') || field.parentNode;
  let errorElement = formGroup.querySelector('.help-block');

  if (!errorElement) {
    errorElement = Object.assign(document.createElement('span'), { className: 'help-block' });
    field.parentNode.appendChild(errorElement);
  }

  errorElement.innerHTML = errorMessage;

  if (formGroup && !formGroup.classList.contains('has-error')) {
    formGroup.classList.add('has-error');
  }
}

function validateFileType(field) {
  if (!field.dataset.acceptFileTypes) { return true; }

  const errorMessage = field.dataset.invalidFileTypeMessage;
  const acceptFileTypes = field.dataset.acceptFileTypes.replace('\\A', '^').replace('\\z', '$');

  for (let i = 0; i < field.files.length; i++) {

    if (!field.files[i].type.match(acceptFileTypes)) {
      addFileError(field, errorMessage);
      field.value = '';
      return false;
    }
  }

  return true;
}

function validateFileSize(field) {
  if (!field.dataset.maxFileSize) { return true; }

  let maxFileSize = field.dataset.maxFileSize;

  for (let i = 0; i < field.files.length; i++) {
    if (field.files[i].size > maxFileSize) {
      addFileError(field, 'File is too big');
      field.value = '';
      return false;
    }
  }

  return true;
}

function handleDirectUploadChange(e) {
  const validFile = validateFileSize(e.target) && validateFileType(e.target);
  if (validFile) { uploadFiles(e.target); }
}
